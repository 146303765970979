import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const heroText =
  "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua."

export default function Projects() {
  return (
    <Layout
      bodyClass="projects"
      heroTitle="Projects"
      heroText={heroText}
      bcText="PROJECTS"
    >
      <section className="projects-section">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-1.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-2.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-3.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-1.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-2.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-3.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-1.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-2.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="project-item-wrap">
                <Link to="/project-single">
                  <img src="images/redi-project-3.jpg" alt="" />
                  <div className="project-content-wrap">
                    <p className="project-subtitle">2017-2020</p>
                    <h2 className="project-title">Lorem Project</h2>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
